<template>
  <div>
    <NavigationBar :title="title" v-show="is_navbar === 'true'"></NavigationBar>
    <div class="container" :style="{ top: this.offsetTop }">
      <div class="bgc_container">
        <div class="bg_contianer"></div>
        <div class="head_top">
          <div class="head_top_left">
            <img
              src="@/static/images/verification_report1@2x.png"
              alt=""
              srcset=""
              class="report1Img"
            />
            <div class="left_text">此账号已通过验号，信息真实，请放心购买</div>
          </div>
          <div class="head_top_right">
            <img
              src="@/static/images/verification_report2@2x.png"
              alt=""
              srcset=""
              class="report2Img"
            />
          </div>
        </div>
        <div class="body_center">
          <div class="game_name">
            {{ userInfo.game_name || "王者荣耀" }}
          </div>
          <div class="uid_container">
            <div class="uid_text">营地ID：{{ userInfo.uid }}</div>
            <div class="uid_btn" @click="copyItemNo(userInfo.uid, '复制成功')">
              复制
            </div>
          </div>
          <div class="line"></div>
          <div class="item_container">
            <div class="item">
              <div class="item_top">{{ userInfo.game_area_service }}</div>
              <div class="item_name">游戏区服</div>
            </div>
            <div class="item">
              <div class="item_top">{{ userInfo.heroNum }}</div>
              <div class="item_name">英雄数量</div>
            </div>
            <div class="item">
              <div class="item_top">{{ userInfo.skinNum }}</div>
              <div class="item_name">皮肤数量</div>
            </div>
            <div class="item">
              <div class="item_top">{{ userInfo.skinDcNum }}</div>
              <div class="item_name">典藏皮肤数量</div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="body_bottom">
            <div class="selectGameContianer">
              <van-tabs
                title-active-color="#222222"
                line-height="7px"
                @click="getTypeKey"
                v-model="typeKey"
              >
                <van-tab
                  v-for="item in heroType"
                  :title="item.heroType"
                  :key="item.typeKey"
                  :name="item.typeKey"
                >
                </van-tab>
              </van-tabs>
              <div class="vanTabsContainer">
                <div class="vanTabsItemContainer">
                  <div
                    :class="{ active: defaultIndex === item.classType }"
                    :style="{
                      margin:
                        item.classType === 0 ? '0px 8px 0px 0px' : '0px 8px',
                    }"
                    class="vanTabsItem"
                    v-for="item in skinType"
                    :key="item.classType"
                    @click="getStatus(item.classType)"
                  >
                    {{ item.classTypeName }}
                  </div>
                </div>
              </div>
            </div>
            <div class="gameDetailsContainer" v-show="userHero.length > 0">
              <div
                class="gameDetailsItem"
                v-for="(item, index) in userHero"
                :key="index"
                :class="{ activeUserHero: userHero.length === index + 1 }"
              >
                <div class="gameDetailsItemLeft">
                  <img :src="item.logo" alt="" class="gameLeftImg" />
                  <div class="gameLeftName">{{ item.name }}</div>
                </div>
                <div class="gameDetailsItemRight">
                  <div
                    class="skinContainer"
                    v-for="(itemSkin, indexSkin) in item.skinList"
                    :key="indexSkin"
                  >
                    <div class="userHeroImgContainer">
                      <img :src="itemSkin.img" alt="" class="skinImg" />
                      <div
                        v-show="
                          itemSkin.class_type != 0 && itemSkin.class_type != 4
                        "
                        class="skinType"
                        v-text="getClassTypeName(itemSkin.class_type)"
                      ></div>
                    </div>
                    <div class="skinName">{{ itemSkin.name }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="defaultPageContainer" v-show="userHero.length === 0">
              <div class="imgContainer">
                <img
                  src="@/static/images/defaultPage2@2x.png"
                  alt=""
                  class="defaultPageImg"
                />
                <div class="defaultPageText">什么都没有哦~</div>
              </div>
            </div>
          </div>
          <div class="bottom_text">到底了～</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NavigationBar } from "@/components";
import { getWzryAccountDetail } from "@/api/homeapi";
import { Toast } from "vant";
import { gitIosAppleModels } from "@/utils/gitIosAppleModels";
export default {
  name: "VerificationReport",
  data() {
    return {
      defaultIndex: 0,
      typeKey: -1,
      title: "验号报告",
      userInfo: {
        realNameValue: "",
        game_area_service: "",
      },
      // 英雄标签
      heroType: [],
      skinType: [],
      userHero: [],
      oldUserHero: [],
      offsetTop: "0px",
      is_navbar: "true",
    };
  },
  components: {
    NavigationBar,
  },
  async mounted() {
    if (JSON.stringify(this.$route.query) != "{}") {
      this.is_navbar = this.$route.query.is_navbar;
      let config = {
        goods_id: this.$route.query.goods_id,
      };
      this.is_navbar = this.$route.query.is_navbar || "true";
      const u = navigator.userAgent;
      const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isAndroid) {
        this.offsetTop = "1.226667rem";
      } else if (isiOS) {
        let iosSafeArea = this.$route.query.iosSafeArea;
        let ios_6_7_8 = gitIosAppleModels();
        if (ios_6_7_8) {
          this.offsetTop = iosSafeArea
            ? iosSafeArea / (document.documentElement.clientWidth / 10) + "rem"
            : "1.226667rem";
        } else {
          this.offsetTop = iosSafeArea
            ? iosSafeArea / (document.documentElement.clientWidth / 10) + "rem"
            : 90 / (document.documentElement.clientWidth / 10) + "rem";
        }
      }
      if (this.is_navbar === "false") {
        this.offsetTop = "0px";
      }
      await this.requestGetWzryAccountDetail(config);
    }
  },
  methods: {
    // 修改样式
    getStatus(classType) {
      this.defaultIndex = classType;
      this.getFilterUserHero();
    },
    async requestGetWzryAccountDetail(configDetail) {
      try {
        const result = await getWzryAccountDetail(configDetail);
        if (result.code === 20000 || result.status_code === 1000) {
          this.userInfo = result.data.userInfo;
          this.heroType = [
            { heroType: "全部", typeKey: -1 },
            ...result.data.heroSkinType.heroType,
          ];
          this.skinType = result.data.heroSkinType.skinType;
          this.userHero = result.data.userHero;
          this.oldUserHero = result.data.userHero;
        } else {
          Toast.fail(result.message);
        }
      } catch (error) {
        Toast.fail("请求失败");
      }
    },
    getTypeKey(name) {
      this.typeKey = name;
      this.getFilterUserHero();
    },
    // 数据过滤
    getFilterUserHero() {
      let copyUserHero;
      if (this.typeKey === -1) {
        copyUserHero = JSON.parse(JSON.stringify(this.oldUserHero));
      } else {
        copyUserHero = JSON.parse(JSON.stringify(this.oldUserHero));
        copyUserHero = copyUserHero.filter((item) => {
          return item.typeKey === this.typeKey;
        });
      }
      if (this.defaultIndex === 0) {
        this.userHero = copyUserHero;
      } else {
        copyUserHero = copyUserHero.reduce((prev, item) => {
          let filterSkinList = item.skinList.filter((itemValue) => {
            return Number(itemValue.class_type) === this.defaultIndex;
          });
          filterSkinList.length > 0
            ? prev.push({
                ...item,
                skinList: filterSkinList,
              })
            : prev;
          return prev;
        }, []);
        this.userHero = copyUserHero;
      }
    },
    getClassTypeName(class_type) {
      const skinTypeName = this.skinType.find((item) => {
        return item.classType == class_type;
      });
      return (skinTypeName || {}).classTypeName;
    },
    copyItemNo(orderId, text) {
      const textString = orderId.toString(); // 数字没有 .length 不能执行selectText 需要转化成字符串
      let input = document.createElement("input");
      input.readOnly = "readOnly"; // 防止ios聚焦触发键盘事件
      input.style.position = "fixed";
      input.style.left = "-9999px";
      input.value = textString;
      document.body.appendChild(input);
      input.select();
      // ios必须先选中文字且不支持 input.select();
      // this.selectText(input, 0, textString.length);
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        //兼容ios
        if (!document.execCommand("Copy")) {
          input.setSelectionRange(0, input.value.length);
          this.$toast(text);
        }
      }
      if (document.execCommand("Copy")) {
        document.execCommand("Copy");
        this.$toast(text);
      } else {
        this.$toast("复制失败");
      }
      document.body.removeChild(input);
      // input.blur();
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #f7f8fa;
  min-height: calc(100vh - 40px);
  overflow: scroll;
  .bgc_container {
    position: relative;
    .bg_contianer {
      position: absolute;
      top: 0px;
      width: 375px;
      height: 496px;
      background: linear-gradient(0deg, #ffffff 0%, #d4f3ff 16%, #a1d6ff 100%);
    }
    .head_top {
      .head_top_left {
        position: absolute;
        top: 24px;
        left: 32px;
        .report1Img {
          width: 154px;
          height: 25px;
        }
        .left_text {
          width: 228px;
          height: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #222222;
          line-height: 16px;
          text-align: left;
          font-style: normal;
        }
      }
      .head_top_right {
        position: absolute;
        top: 0px;
        right: 0px;
        .report2Img {
          width: 136px;
          height: 93px;
        }
      }
    }
    .body_center {
      position: absolute;
      top: 93px;
      left: 50%;
      transform: translate(-50%);
      width: 351px;
      background: #ffffff;
      border-radius: 10px;
      padding: 14px 15px 12px 14px;
      box-sizing: border-box;
      .game_name {
        height: 22px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 16px;
        color: #222222;
        line-height: 22px;
        text-align: left;
        font-style: normal;
      }
      .uid_container {
        display: flex;
        align-items: center;
        margin-top: 4px;
        .uid_text {
          height: 17px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          line-height: 17px;
          text-align: left;
          font-style: normal;
        }
        .uid_btn {
          width: 32px;
          height: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 10px;
          color: #aaaaaa;
          line-height: 14px;
          text-align: center;
          font-style: normal;
          border-radius: 24px;
          border: 1px solid #aaaaaa;
          margin-left: 4px;
        }
      }
      .line {
        width: 319px;
        border: 0.5px solid #eeeeee;
        box-sizing: border-box;
        margin: 9px 0px;
      }
      .item_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item {
          .item_top {
            height: 17px;
            font-family: Roboto, Roboto;
            font-weight: 500;
            font-size: 12px;
            color: #222222;
            line-height: 17px;
            text-align: left;
            font-style: normal;
            white-space: nowrap;
          }
          .item_name {
            height: 17px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 12px;
            color: #aaaaaa;
            line-height: 17px;
            text-align: left;
            font-style: normal;
            padding-top: 6px;
            white-space: nowrap;
          }
        }
      }
    }
    .bottom {
      position: absolute;
      top: 232px;
      left: 50%;
      transform: translate(-50%);
      .body_bottom {
        width: 351px;
        background: #ffffff;
        border-radius: 10px;
        min-height: calc(100vh - 290px);
        .selectGameContianer {
          height: 87px;
          padding-left: 9px;
          overflow: hidden;
          .vanTabsContainer {
            margin-top: 1px;
            .vanTabsItemContainer {
              width: 100%;
              white-space: nowrap;
              overflow-x: auto;
              display: flex;
              align-items: center;
              .vanTabsItem {
                width: 28px;
                height: 20px;
                background: #f2f2f2;
                border-radius: 14px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #777777;
                line-height: 20px;
                text-align: center;
                margin: 0px 8px;
                padding: 4px 16px;
                cursor: pointer;
              }
            }
            .vanTabsItemContainer::-webkit-scrollbar {
              display: none;
            }
          }
        }
        .gameDetailsContainer {
          .gameDetailsItem {
            height: 97px;
            display: flex;
            padding-bottom: 11px;
            padding-top: 10px;
            margin-left: 15px;
            border-bottom: 1px solid #ececec;
            .gameDetailsItemLeft {
              height: 100%;
              // width: 77px;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-right: 12px;
              .gameLeftImg {
                width: 50px;
                height: 48px;
                border-radius: 4px;
              }
              .gameLeftName {
                height: 17px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 17px;
                text-align: center;
              }
            }
            .gameDetailsItemRight {
              width: 100%;
              height: 100%;
              white-space: nowrap;
              overflow-x: auto;
              overflow-y: hidden;
              // background: #f2f2f2;
              display: flex;
              .skinContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                margin-right: 8px;
                .userHeroImgContainer {
                  width: 56px;
                  height: 76px;
                  position: relative;
                  .skinImg {
                    width: 56px;
                    height: 76px;
                    border-radius: 4px;
                  }
                  .skinType {
                    width: 56px;
                    height: 16px;
                    background: rgba(0, 0, 0, 0.65);
                    height: 14px;
                    font-size: 10px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 14px;
                    text-align: center;
                    border-radius: 0px 0px 4px 4px;
                    position: absolute;
                    bottom: 0px;
                  }
                }
                .skinName {
                  height: 14px;
                  font-size: 10px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  color: #333333;
                  line-height: 14px;
                  text-align: center;
                }
              }
            }
            .gameDetailsItemRight::-webkit-scrollbar {
              display: none;
            }
          }
        }
        .defaultPageContainer {
          .imgContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 40px;
            .defaultPageImg {
              width: 120px;
              height: 120px;
            }
            .defaultPageText {
              height: 24px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              font-size: 16px;
              color: #1f1f1f;
              line-height: 24px;
              text-align: right;
              font-style: normal;
            }
          }
        }
      }
      .bottom_text {
        width: 100%;
        height: 50px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #777777;
        line-height: 50px;
        text-align: center;
        font-style: normal;
      }
    }
  }
}
.active {
  background: #508aff !important;
  color: #ffffff !important;
}
// .allactive {
//   padding-left: 15px !important;
// }
::v-deep .van-tabs__line {
  border-radius: 0px !important;
  width: 26px;
  height: 7px;
  background: linear-gradient(270deg, #a1d0ff 0%, #5695fc 100%);
}
::v-deep .van-tabs__nav--line.van-tabs__nav--complete {
  padding-left: 0px;
}
::v-deep .van-tab {
  height: 34px;
}
::v-deep .van-tabs__nav--line {
  height: 30px;
}
::v-deep .van-tab__text {
  // width: 28px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 20px;
}
.activeUserHero {
  border: 0px !important;
}
::v-deep .van-tabs--line .van-tabs__wrap {
  border-radius: 5px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0px;
}
</style>
